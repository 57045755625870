import * as actions from '../actions/viewEngagementActions';
import _ from 'underscore';

const initialState = {
	loading: false,
	openInformationDialog: false,
	openChatSettings: false,
};

export const viewEngagementReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SET_VIEWER_ENGAGEMENT_LOADING: {
			return {
				...state,
				loading: action.loading,
			};
		}
		case actions.SET_VIEWER_ENGAGEMENT_OPEN_INFORMATION_DIALOG: {
			return {
				...state,
				openInformationDialog: action.openInformationDialog,
			};
		}
		case actions.SET_VIEWER_ENGAGEMENT_OPEN_CHAT_SETTINGS: {
			return {
				...state,
				openChatSettings: action.openChatSettings,
			};
		}
		default:
			return state;
	}
};
