import BackendClient from './backendClient';
import {
	play2ConfigBaseUrl,
	ingestEndpointV1,
	readApiEndpointV1,
	writeApiEndpointV1,
	socialSharingRedirectUrl,
	cdnAdminEndpointV1,
	cdnWorkflowEndpointV1,
	workflowEndpoint,
	transcribeEndpointV1,
	ipOnlyWorkflowEndpointV1,
} from '../utils/config';

export const processSearchQuery = (accountId, mediaName, query) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/${mediaName}/${query}`, false);

export const saveNewFilterForSearchQuery = (accountId, filterName, guid, searchQuery, sortOrder, limit) =>
	BackendClient.post(
		`${writeApiEndpointV1}${accountId}accounts/${accountId}/queries`,
		{
			id: guid,
			name: filterName,
			q: searchQuery,
			sort: 'relevance ' + sortOrder,
			limit: limit,
		},
		false
	);

export const saveNewFilterForLibrarySearchQuery = (accountId, filterName, guid, searchQuery, sortOrder, limit) =>
	BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/queries`,
		{
			id: guid,
			name: filterName,
			q: searchQuery,
			sort: 'relevance ' + sortOrder,
			limit: limit,
		},
		false
	);

export const deleteSearchFilter = (accountId, filterId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/queries/${filterId}`);

export const loadTheSelectedMediaSearchFilterQuery = (accountId, filterId) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/queries/${filterId}`, false);

export const postQueriesForSearchResult = (accountId, guid, playListName, searchQuery, sortOrder, searchLimit) =>
	BackendClient.post(`${writeApiEndpointV1}accounts/${accountId}/queries`, {
		id: guid,
		name: playListName,
		q: searchQuery,
		sort: sortOrder,
		limit: searchLimit,
	});

export const postPlayListsQuery = (accountId, guid, queryId, newPLaylistName, assetId) =>
	BackendClient.post(`${writeApiEndpointV1}accounts/${accountId}/playlists`, {
		id: guid,
		metadata: {
			title: newPLaylistName,
		},
		query: {
			id: queryId,
		},
		asset: {
			id: assetId,
		},
	});

export const loadMediaItemsFullCount = (accountId, mediaName, query) =>
	BackendClient.getTotalCount(`${readApiEndpointV1}accounts/${accountId}/${mediaName}${query}`);

export const loadMediasByQueryService = ({ accountId, mediaName, query }) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/${mediaName}${query}`);

export const loadTrashedMediasByQueryService = ({ accountId, query }) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/medias/trash${query}`);

export const loadMediasPerNavigation = ({ accountId, mediaPerPage, offset, mediaName, sortType, query }) => {
	return BackendClient.get(
		`${readApiEndpointV1}accounts/${accountId}/${mediaName}&limit=${mediaPerPage}&offset=${offset}&sort=${encodeURIComponent(
			sortType
		)}` + (query ? query : '')
	);
};

export const loadTrashedMediasPerNavigation = ({ accountId, mediaPerPage, offset, sortType, query }) => {
	return BackendClient.get(
		`${readApiEndpointV1}accounts/${accountId}/medias/trash?limit=${mediaPerPage}&offset=${offset}&sort=${encodeURIComponent(
			sortType
		)}` + (query ? query : '')
	);
};

export const getTrashedMediaById = ({ accountId, mediaId }) => {
	return BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/medias/trash/${mediaId}`);
};

export const loadMediasPerNavigationWithTotalCount = ({
	accountId,
	mediaPerPage,
	offset,
	mediaName,
	sortType,
	query,
	signal,
}) =>
	BackendClient.getPaginationData(
		`${readApiEndpointV1}accounts/${accountId}/${mediaName}${
			mediaPerPage ? `&limit=${mediaPerPage}` : ''
		}&offset=${offset}&sort=${encodeURIComponent(sortType)}` + (query ? `&${query}` : ''),
		undefined,
		signal
	);

export const loadTrashedMediasPerNavigationWithTotalCount = ({
	accountId,
	mediaPerPage,
	offset,
	sortType,
	query,
	signal,
}) =>
	BackendClient.getPaginationData(
		`${readApiEndpointV1}accounts/${accountId}/medias/trash?${
			mediaPerPage ? `limit=${mediaPerPage}` : ''
		}&offset=${offset}&sort=${encodeURIComponent(sortType)}` + (query ? `&${query}` : ''),
		undefined,
		signal
	);

export const getMediaQueryDetailsOnIdAndName = (accountId, mediaId, mediaName) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/${mediaName}/${mediaId}`);

export const _moveMediaToBin = ({ accountId, mediaId }) =>
	BackendClient.put(`${writeApiEndpointV1}accounts/${accountId}/medias/${mediaId}/trash`);

export const _restoreMedia = ({ accountId, mediaId }) =>
	BackendClient.put(`${writeApiEndpointV1}accounts/${accountId}/medias/${mediaId}/restore`);

export const deleteMediaItemPerAccount = (mediaId, accountId, mediaName) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/${mediaName}/${mediaId}`);

export const deleteAssetPerAccount = (accountId, assetId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/assets/${assetId}`);

export const getAssetPerAccount = (accountId, assetId) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/assets/${assetId}`);

export const loadCatalogs = (accountId, query, offset) =>
	BackendClient.get(
		`${readApiEndpointV1}accounts/${accountId}/${query ?? 'catalogs?'}&limit=200&offset=${offset ?? 0}`
	);

export const getTotalCatalogsCount = (accountId, query, offset) =>
	BackendClient.getTotalCount(`${readApiEndpointV1}accounts/${accountId}/${query}&limit=200&offset=${offset}`);

export const loadProfiles = (accountId) => BackendClient.get(`${cdnWorkflowEndpointV1}accounts/${accountId}/workflows`);

export const getAllFlows = (accountId, accessToken) =>
	BackendClient.get(`${cdnWorkflowEndpointV1}accounts/${accountId}/workflows?token=${accessToken}`);

export const loadMediaCountsOnCatelogsService = (accountId, limit) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/catalogs?limit=${limit}&offset=0`);

export const uploadMediaItemsFlow = (accountId, encodingProfile = '', selectedItem, catalogId, emails, fileName) =>
	BackendClient.postUploadVideoFlow(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: 'v1-upload-standard',
				scope: 'global',
			},
			input: {
				flowId: encodingProfile,
				mediaTitle: removeFileExtension(fileName),
				filename: selectedItem.name,
				assetId: removeFileExtension(selectedItem.name),
				mediaId: removeFileExtension(selectedItem.name),
				catalogId: catalogId ? catalogId : '',
				emailRecipients: emails,
			},
		},
		false,
		selectedItem.name
	);

export const uploadMediaUsingQBrickEncode = (
	accountId,
	encodingProfile = '',
	selectedItem,
	catalogId,
	emails,
	fileName
) =>
	BackendClient.postUploadVideoFlow(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: 'v1-qbrick-encode-email',
				scope: 'global',
			},
			input: {
				flowId: encodingProfile,
				mediaTitle: removeFileExtension(fileName),
				filename: selectedItem.name,
				assetId: removeFileExtension(selectedItem.name),
				mediaId: removeFileExtension(selectedItem.name),
				catalogId: catalogId ? catalogId : '',
				emailRecipients: emails,
			},
		},
		false,
		selectedItem.name
	);

export const replaceMediaUsingQBrickEncode = (
	accountId,
	encodingProfile = '',
	selectedItem,
	_catalogId,
	emails,
	_fileName,
	{ mediaId, assetId }
) =>
	BackendClient.postUploadVideoFlow(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: 'v1-qbrick-encode-email',
				scope: 'global',
			},
			input: {
				flowId: encodingProfile,
				replaceAssetId: assetId,
				mediaId,
				emailRecipients: emails,
			},
		},
		false,
		selectedItem.name
	);

export const uploadMediaItems = (path, selectedItem, mediaCatalog = '', xCallBack) =>
	BackendClient.postUploadVideo(
		`${path}${mediaCatalog ? '?publicationId=' + mediaCatalog : ''}`,
		{
			file: selectedItem,
		},
		true,
		xCallBack
	);

const removeFileExtension = (filename) => {
	return filename.split('.').slice(0, -1).join('.');
};

export const uploadMediaItemsOld = (accountId, encodingProfile = '', mediaCatalog = '', accessToken, selectedItem) =>
	BackendClient.post(
		`${ingestEndpointV1}${accountId}/flows/${encodeURIComponent(encodingProfile)}${
			mediaCatalog ? '?publicationId=' + mediaCatalog : ''
		}`,
		{
			file: selectedItem,
		},
		true
	);

export const uploadAudioItems = (path, selectedItem, xCallBack) => {
	BackendClient.postUploadAudio(
		`${path}`,
		{
			file: selectedItem,
		},
		true,
		xCallBack
	);
};

export const _getAllCurrentJobs = (accountId, from, to) =>
	BackendClient.get(`${ipOnlyWorkflowEndpointV1}accounts/${accountId}/current/all?from=${from}&to=${to}`, false);
export const _getAllFinishedJobs = (accountId, from, to) =>
	BackendClient.get(`${ipOnlyWorkflowEndpointV1}accounts/${accountId}/finished/all?from=${from}&to=${to}`, false);

export const loadPlayLists = (accountId, mediaPerPage, offset, sortType) =>
	BackendClient.get(
		`${readApiEndpointV1}accounts/${accountId}/playlists?limit=${mediaPerPage}&offset=${offset}&sort=${encodeURIComponent(
			sortType
		)}`,
		false
	);

export const loadAllPlayLists = (accountId) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/playlists`, false);

export const newAssets = (accountId, uuid) =>
	BackendClient.post(`${writeApiEndpointV1}accounts/${accountId}/assets`, { id: uuid }, false);

export const newPlaylist = (accountId, assetsId, playlistTitle, uuid) =>
	BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/playlists`,
		{
			metadata: {
				title: playlistTitle,
			},
			asset: {
				id: assetsId,
			},
			id: uuid,
		},
		false
	);

export const newCatalog = (accountId, assetsId, catalogTitle, uuid, parentId) =>
	BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/catalogs`,
		parentId
			? {
					metadata: {
						title: catalogTitle,
					},
					asset: {
						id: assetsId,
					},
					id: uuid,
					parentId: parentId,
			  }
			: {
					asset: {
						id: assetsId,
					},
					id: uuid,
					metadata: {
						title: catalogTitle,
					},
					title: catalogTitle,
			  },
		false
	);

export const updateUserRestrictionToCatalog = (accountId, catalogId, admins, users) => {
	return BackendClient.put(
		`${writeApiEndpointV1}accounts/${accountId}/catalogs/${catalogId}/access`,
		{
			admins,
			users,
		},
		false,
		false
	);
};

export const deletePlaylistItemPerAccount = (playlistId, accountId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/playlists/${playlistId}`);

export const thumbImageUploadPassFlow = (accountId, selectedItem, assetId) =>
	BackendClient.post(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: 'v1-upload-image',
			},
			input: {
				flowId: 'image',
				mediaTitle: selectedItem.name,
				assetId: assetId,
			},
		},
		false
	);

export const audioUploadPassFlow = (accountId, assetId, language, resourceId, audioDescription) =>
	BackendClient.post(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: 'v1-upload-attach-audio',
			},
			input: {
				language: language,
				audioDescription: audioDescription,
				assetId: assetId,
			},
		},
		false
	);

export const subtitleUploadPassFlow = (accountId, assetId, language) =>
	BackendClient.post(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: 'v1-upload-subtitle',
			},
			input: {
				flowId: 'subtitle',
				language: language,
				assetId: assetId,
			},
		},
		false
	);

export const thumbImageUploadFlowStartedOld = (accountId, accessToken, guid, fileGuid, selectedItem) =>
	BackendClient.post(
		`${ingestEndpointV1}${accountId}/flows/${fileGuid}/assets/${guid}?token=${accessToken}`,
		{
			file: selectedItem,
		},
		true,
		accessToken
	);

export const thumbImageUploadFlowStarted = (path, selectedItem, xCallBack) =>
	BackendClient.postUploadImage(
		`${path}`,
		{
			file: selectedItem,
		},
		true,
		xCallBack
	);

export const setInteractiveImageRel = (accountId, id, resourceId) =>
	BackendClient.put(
		`${writeApiEndpointV1}accounts/${accountId}/assets/${id}/resources/${resourceId}`,
		{
			language: 'en',
			rel: ['interactiveImage'],
		},
		false,
		false
	);

export const subtitleUploadFlow = (accountId, accessToken) =>
	BackendClient.get(`${cdnAdminEndpointV1}cdnadmin/accounts/${accountId}/subtitleflows?token=${accessToken}`, false);

export const subtitleUploadFlowStarted = (path, selectedItem, xCallBack) =>
	BackendClient.postUploadSubtitle(
		`${path}`,
		{
			file: selectedItem,
		},
		true,
		xCallBack
	);

export const subtitleUploadFlowStartedOld = (
	accountId,
	accessToken,
	assetId,
	flowId,
	mediaId,
	language,
	selectedItem,
	resourceId
) =>
	BackendClient.post(
		`${ingestEndpointV1}${accountId}/flows/${flowId}/medias/${encodeURIComponent(
			mediaId
		)}/assets/${assetId}/resources/${resourceId}?token=${accessToken}&language=${language}&type=subtitle`,
		{
			file: selectedItem,
		},
		true,
		accessToken
	);

export const deleteSubtitle = (accountId, id, resourceId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/assets/${id}/resources/${resourceId}`);

export const deleteAudio = (accountId, id, resourceId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/assets/${id}/resources/${resourceId}`);
//deleteAudio looks delete resource
export const createResource = (accountId, assetId, resource) =>
	BackendClient.post(`${writeApiEndpointV1}accounts/${accountId}/assets/${assetId}/resources`, {
		resource,
	}); //api not tested still

export const _uploadFileUsingSmilWorkflow = (accountId, assetId, data) =>
	BackendClient.post(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: 'v1-create-smil',
			},
			input: {
				assetId: assetId,
				...(data ?? {}),
			},
		},
		false
	);

export const loadResourcesAfterTheFlowStarted = (accountId, id) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/assets/${id}/resources`, false);

export const loadResourcesAfterTheFlowStartedForMedias = (accountId, id) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/medias/${id}`, false);

export const deleteThumbnailImage = (accountId, id, resourceId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/assets/${id}/resources/${resourceId}`);

export const setThumbnailImageAsDefaultThumbnails = (accountId, mediaId, thumbId) =>
	BackendClient.post(`${writeApiEndpointV1}accounts/${accountId}/medias/${mediaId}/thumbnails`, { id: thumbId });

export const removeResourceAsThumbnailImage = (accountId, mediaId, resourceId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/medias/${mediaId}/thumbnails/${resourceId}`);

export const updateResourceOfMediaAsset = (accountId, assetId, resource) =>
	BackendClient.put(
		`${writeApiEndpointV1}accounts/${accountId}/assets/${assetId}/resources/${resource.id}`,
		resource,
		false,
		false
	);

export const updateMediaCoverOfMedia = (accountId, mediaId, trackId, coverId, cover) =>
	BackendClient.put(
		`${writeApiEndpointV1}accounts/${accountId}/medias/${mediaId}/tracks/${trackId}/covers/${coverId}`,
		cover,
		false,
		false
	);

export const assignMediaItemToPlaylist = (accountId, playListId, mediaId) =>
	BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/playlists/${playListId}/medias`,
		{
			id: mediaId,
		},
		false
	);

export const loadRealativeMediaForPlaylist = (accountId, playListId) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/playlists/${playListId}`);

export const removeMediaItemFromPlaylist = (accountId, playListId, mediaId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/playlists/${playListId}/medias/${mediaId}`);

export const assignMediaItemToCatalog = (accountId, catalogId, mediaId, ifCatalog) =>
	BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/catalogs/${catalogId}/${ifCatalog ? 'children' : 'medias'}`,
		{
			id: mediaId,
		},
		false
	);

export const deleteMediaItemFromSpecificCatalog = (accountId, catalogId, mediaId, ifCatalog) =>
	BackendClient.delete(
		`${writeApiEndpointV1}accounts/${accountId}/catalogs/${catalogId}/${ifCatalog ? 'children' : 'medias'}/` +
			mediaId
	);

export const changeTheOrderOfTheSelectedMediaItemInPlaylist = (accountId, playlistId, resource) =>
	BackendClient.put(`${writeApiEndpointV1}accounts/${accountId}/playlists/${playlistId}`, resource, false, false);

export const saveMediaDetails = (accountId, mediaId, mediaName, body) =>
	BackendClient.put(`${writeApiEndpointV1}accounts/${accountId}/${mediaName}/${mediaId}`, body, false, false);

export const updateExistingAssignedRestrictionRule = (accountId, restrictionId, body) =>
	BackendClient.put(
		`${writeApiEndpointV1}accounts/${accountId}/restrictionRules/${restrictionId}`,
		body,
		false,
		false
	);

export const createNewAssignedRestrictionRule = (accountId, body) =>
	BackendClient.post(`${writeApiEndpointV1}accounts/${accountId}/restrictionRules`, body);

export const applyAssignedRestrictionRuleOnMedia = (accountId, mediaName, mediaId, body) =>
	BackendClient.post(`${writeApiEndpointV1}accounts/${accountId}/${mediaName}/${mediaId}/restrictions`, body);

export const deleteAssignedRestrictionRuleOnMedia = (accountId, mediaName, mediaId, restrictionId) =>
	BackendClient.delete(
		`${writeApiEndpointV1}accounts/${accountId}/${mediaName}/${mediaId}/restrictions/${restrictionId}`
	);

export const deleteRestrictionRuleId = (accountId, restrictionId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/restrictionRules/${restrictionId}`);

export const getAssignedRestrictionRules = (accountId) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/restrictionRules`);

export const deleteAssignedRendition = (accountId, assetId, resourceId, renditionGroup, renditionId) =>
	BackendClient.delete(
		`${writeApiEndpointV1}accounts/${accountId}/assets/${assetId}/resources/${resourceId}/${renditionGroup}/${renditionId}`
	);

export const loadSearchPredefinedFilters = (accId) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accId}/queries`);

export const loadPlayerEmbedConfiguration = (accountId) =>
	BackendClient.get(`${play2ConfigBaseUrl}${accountId}/configurations`);

export const loadPlayerConfigurationById = (accountId, id) =>
	BackendClient.get(`${play2ConfigBaseUrl}${accountId}/configurations/${id}`);

export const loadTags = (accountId) => BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/tags`);

export const updateSearchFilter = (selectedFilter, filterId, accountId) =>
	BackendClient.put(`${writeApiEndpointV1}accounts/${accountId}/queries/${filterId}`, selectedFilter, false, false);

export const updateSearchFilterQuery = (accountId, filterName, filterId, searchQuery, sortOrder, limit) =>
	BackendClient.put(
		`${writeApiEndpointV1}${accountId}accounts/${accountId}/queries/${filterId}`,
		{
			Name: filterName,
			Q: searchQuery,
			Sort: 'relevance ' + sortOrder,
			Limit: limit,
		},
		false
	);

export const twitterAuthenticate = (accountId, body) =>
	BackendClient.post(
		`${readApiEndpointV1}sharing/accounts/${accountId}/platforms/Twitter01/authorizations/twitter`,
		body
	);

export const getSocialMedialAuthenticationDetails = (accountId) =>
	BackendClient.get(`${readApiEndpointV1}sharing/accounts/${accountId}/platforms`);

export const removeSocialMediaAccount = (accountId, platform) =>
	BackendClient.delete(`${writeApiEndpointV1}sharing/accounts/${accountId}/platforms/${platform}`);

export const shareOnSocialMediaPlatform = (accountId, mediaId, post) =>
	BackendClient.post(`${writeApiEndpointV1}sharing/accounts/${accountId}/medias/${mediaId}/shares`, post);

export const getSharedPostsStatus = (accountId, mediaId) =>
	BackendClient.get(`${readApiEndpointV1}sharing/accounts/${accountId}/medias/${mediaId}`);

export const deleteTheSharedPost = (accountId, mediaId, sharedId) =>
	BackendClient.delete(`${writeApiEndpointV1}sharing/accounts/${accountId}/medias/${mediaId}/shares/${sharedId}`);

export const authorizeTheSocialMediaPlatform = (accountId, code, platformId, platform, clientId) =>
	BackendClient.post(
		`${writeApiEndpointV1}sharing/accounts/${accountId}/platforms/${platformId}/authorizations/${platform}`,
		{
			code: code,
			clientId: `${clientId}`,
			redirectUri: `${socialSharingRedirectUrl}`,
		}
	);

export const loadPlaylistRelatedMedias = (accountId, playlistId) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/playlists/${playlistId}/medias`);

export const fetchLiveAssetInfo = (accountId, assestId) =>
	BackendClient.get(cdnAdminEndpointV1 + 'cdnadmin/accounts/' + accountId + '/liveassets/' + assestId);

export const removeTrackFromMedia = (accountId, mediaId, trackId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/medias/${mediaId}/tracks/${trackId}`);

export const createNewMedia = (accountId, body, mediaName) =>
	BackendClient.post(`${writeApiEndpointV1}accounts/${accountId}/${mediaName}`, body);

export const transcribeStart = (accountId, mediaId, language, translationLanguages) => {
	return BackendClient.postTrascription(
		`${writeApiEndpointV1}text/accounts/${accountId}/medias/${mediaId}/transcription`,
		{
			language: language,
			translations: [...(translationLanguages ?? [])],
		}
	);
};

export const transcribeProgress = (accountId, mediaId) =>
	BackendClient.get(`${writeApiEndpointV1}text/accounts/${accountId}/medias/${mediaId}/transcription`);

export const getExternalUrlTrascription = () =>
	BackendClient.getJson(`https://qbrick-player.qbrick.com/subtitles/import-st-large.json`);

export const addTranslationDetails = (accountId, mediaId, tokenId, language, charCount) =>
	BackendClient.get(
		`${transcribeEndpointV1}addTranslationDetails?account=${accountId}&media=${mediaId}&token=${tokenId}&language=${language}&charCount=${charCount}`
	);

export const transcribeSave = (accountId, mediaId, tokenId, body) =>
	BackendClient.putTrascription(
		`${writeApiEndpointV1}text/accounts/${accountId}/medias/${mediaId}/transcription`,
		body
	);

export const transcribeSaveSRT = (accountId, mediaId, tokenId, body, language, filename) =>
	BackendClient.postTranscribedSub(
		`${transcribeEndpointV1}saveSrt?filename=${filename}&language=${language}&media=${mediaId}&account=${accountId}&token=${tokenId}`,
		body
	);

export const deleteTranscript = (accountId, mediaId) =>
	BackendClient.delete(`${writeApiEndpointV1}text/accounts/${accountId}/medias/${mediaId}/transcription`);

export const fetchLink = (path) => BackendClient.get(path);

export const _removeChannelFromLiveMedia = (accountId, assetId, channelId) =>
	BackendClient.post(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: 'v1-remove-channel-from-live-media',
			},
			input: {
				assetId: assetId,
				channelId: channelId,
			},
		},
		false
	);

export const _addChannelToLiveMedia = (accountId, mediaId, assetId, channelId) =>
	BackendClient.post(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: 'v1-add-channel-to-live-media',
			},
			input: {
				mediaId: mediaId,
				assetId: assetId,
				channelId: channelId,
			},
		},
		false
	);

export const uploadImageMedia = (accountId, encodingProfile, selectedItem, catalogId, emails, fileName) =>
	BackendClient.post(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: 'v1-upload-image-standard',
			},
			input: {
				flowId: 'image',
				mediaTitle: removeFileExtension(fileName),
				filename: selectedItem.name,
				assetId: removeFileExtension(selectedItem.name),
				mediaId: removeFileExtension(selectedItem.name),
				catalogId: catalogId ? catalogId : '',
				emailRecipients: emails,
			},
		},
		false
	);

export const getCatalogAndTotalCount = (accountId, query, offset, limit) =>
	BackendClient.getPaginationData(
		`${readApiEndpointV1}accounts/${accountId}/${query}&limit=${limit}&offset=${offset}`,
		undefined,
		undefined
	);
