import React, { useCallback, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	InputAdornment,
	TextField,
} from '@material-ui/core';
import { LiveHelp, RefreshOutlined, Send, Settings, Sms } from '@material-ui/icons';

import { joinClassNames } from '../../../services/elementHelperService';
import Icons from '../../../reusable/IconsStore/Icons';

export namespace ViewerEngagementTemplate {
	export const baseClassName = 'viewer-engagement-manager';
	export const TAB = {
		chat: 'chat',
		qna: 'qna',
		polls: 'polls',
		settings: 'settings',
	};

	export const TabItems = [
		{
			label: 'COMMON_CHAT',
			icon: <Sms />,
			value: TAB.chat,
		},
		{
			label: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_LABEL',
			icon: <LiveHelp />,
			value: TAB.qna,
		},
		{
			label: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POLL_LABEL',
			icon: <Icons.Polls />,
			value: TAB.polls,
		},
		{
			label: 'COMMON_SETTINGS',
			icon: <Settings />,
			value: TAB.settings,
		},
	];

	export interface ViewerEngagementSettingsProps {
		mediaId: string;
		mediaDetails?: any;
		reloadData?: () => void;
	}

	export interface ViewerEngagementAdminProfileSettingsProps {
		mediaId: string;
		settings?: Settings;
		onSaveSettings?: () => void;
	}
	export interface AdminProfile {
		chatName: string;
		chatBoxColor?: string;
		chatTextColor?: string;
		chatFontFamily?: string;
	}
	export interface ViewerDataCollection {
		requireUsername?: boolean;
		requireEmail?: boolean;
		privacyPolicyText?: string;
		privacyPolicyUrl?: string;
	}

	export interface ModerateSettings {
		linkToChat?: string;
		recipientCanView?: boolean;
		recipientCanModerate?: boolean;
	}
	export interface Settings {
		created?: string;
		adminProfile?: AdminProfile;
		dataCollection?: ViewerDataCollection;
		moderate?: ModerateSettings;
	}

	export interface ViewerEngagementProps {
		mediaId: string;
		backToList?: () => {};
		isActivated?: boolean;
		mediaDetails?: any;
		onActivateViewerEngagement?: () => void;
		reloadData?: () => void;
	}

	export const InactivatedViewerEngagement: React.FC<ViewerEngagementProps> = ({ onActivateViewerEngagement }) => {
		const { t } = useTranslation();
		return (
			<div className={`${baseClassName}__container ${baseClassName}__container--inactive`}>
				<span className={`${baseClassName}__title`}>
					{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEWER_ENGAGEMENT')}
				</span>
				<p>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_VIEWER_ENGAGEMENT_DESCRIPTION')}</p>
				<Button
					variant="contained"
					color="primary"
					className={`${baseClassName}__activated-btn`}
					onClick={onActivateViewerEngagement}
				>
					{t('COMMON_ACTIVATE')}
				</Button>
				<div style={{ height: '44px' }} />
			</div>
		);
	};

	export interface InputProps {
		children: React.ReactNode;
		value: string;
		placeholder?: string;
		disableSendBtn?: boolean;
		disabled?: boolean;
		onChange?: (_event: React.ChangeEvent<HTMLInputElement>) => void;
		onFocus?: (_event: React.FocusEvent<HTMLInputElement>) => void;
		onSendClick?: (_event: React.MouseEvent<HTMLButtonElement>) => void;
		onShowCreatePoll?: () => void;
		onRefreshClick?: () => void;
	}

	export const Input: React.FC<InputProps> = ({
		children,
		onChange,
		value,
		placeholder,
		onSendClick,
		onRefreshClick,
		onFocus,
		disableSendBtn,
		disabled,
	}) => {
		const { t } = useTranslation();

		const { loading } = useSelector((state: any) => state.viewEngagementReducer);

		const onInputEnter = useCallback(
			(event: React.KeyboardEvent<HTMLInputElement>) => {
				if (event.key === 'Enter' && !disableSendBtn) {
					onSendClick?.(event as any);
				}
			},
			[onSendClick, disableSendBtn]
		);

		return (
			<>
				{children}
				<div className={`${baseClassName}__input-container`}>
					<span className={`${baseClassName}__input--helper`}>{value.trim().length}/300 characters</span>
					<TextField
						disabled={disabled}
						className={`${baseClassName}__input`}
						placeholder={placeholder}
						value={value}
						variant="outlined"
						fullWidth
						onChange={onChange}
						style={disabled ? { opacity: '0.4', color: '#fff' } : { color: '#fff' }}
						onKeyDown={onInputEnter}
						InputProps={{
							endAdornment: (
								<InputAdornment position="start" style={{ marginRight: '0px' }}>
									<IconButton
										onClick={onSendClick}
										edge="end"
										title={t('LABEL_SEND')}
										disabled={disableSendBtn}
									>
										<Send style={disableSendBtn ? { opacity: '0.4' } : undefined} />
									</IconButton>
								</InputAdornment>
							),
						}}
						onFocus={onFocus}
					/>

					{onRefreshClick && (
						<div className={`${baseClassName}__input-action`}>
							<IconButton
								size="medium"
								onClick={onRefreshClick}
								title={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_REFRESH_CHAT')}
								disabled={loading || disabled}
								style={disabled ? { opacity: '0.4' } : undefined}
							>
								<RefreshOutlined />
							</IconButton>
						</div>
					)}
				</div>
			</>
		);
	};

	export const Indicator: React.FC<{ isActive: boolean }> = ({ isActive }) => (
		<div
			className={joinClassNames(
				`${baseClassName}__active-indicator`,
				!isActive ? `${baseClassName}__active-indicator--inactive` : ''
			)}
		/>
	);

	export interface TabContentWrapperProps {
		children: React.ReactNode;
		isChatTab?: boolean;
		useShortContainer?: boolean;
		tabContentWrapperRef?: RefObject<HTMLDivElement>;
		className?: string;
	}

	export const TabContentWrapper: React.FC<TabContentWrapperProps> = ({
		children,
		isChatTab,
		useShortContainer,
		tabContentWrapperRef,
		className,
	}) => {
		return (
			<div
				className={joinClassNames(
					`${baseClassName}__tab-content__wrapper`,
					isChatTab ? `${baseClassName}__tab-content__wrapper--hasInput` : '',
					useShortContainer ? `${baseClassName}__tab-content__wrapper--short` : '',
					className
				)}
				ref={tabContentWrapperRef}
			>
				{children}
			</div>
		);
	};

	export interface InformationDialogProps {
		title?: string;
		textContent?: string;
		actionText?: string;
		open?: boolean;
		onClose?: () => void;
		onAccept?: () => void;
	}

	export const InformationDialog: React.FC<InformationDialogProps> = ({
		open,
		onClose,
		title,
		textContent,
		actionText,
		onAccept,
	}) => {
		return (
			<Dialog
				open={!!open}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{textContent}</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button onClick={onAccept} color="primary" variant="contained">
						{actionText ?? 'OK'}
					</Button>
				</DialogActions>
			</Dialog>
		);
	};
}
